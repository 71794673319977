import React from 'react';
import { Typography, Row, Col, Divider } from 'antd';
import { ApplicationData } from '@bwb-buildings-mono/entities';
import * as UI from '@bwb-buildings-mono/ui';
const { Text, Paragraph } = Typography;

const PHONE_NUMBER = '(818)762-8800';
const EMAIL = 'manager@metroarttolucalake.com';

const tours = [
  {
    name: '2BR, 2BA Tour',
    url: 'https://my.matterport.com/show/?model=YtniJSCTZJB&play=1',
  },
  {
    name: '3BR, 2BA Tour',
    url: 'https://my.matterport.com/show/?model=E57myULJo5X&play=1',
  },
];

const amentities = {
  sentence: (
    <div style={{ fontSize: '18px', lineHeight: '18px', textAlign: 'center' }}>
      <Paragraph>
        <Text mark strong>
          Great location
        </Text>{' '}
        close to restaurants, shops & more!
      </Paragraph>
      <Paragraph>Quiet residential neighborhood - great schools.</Paragraph>
      <Paragraph>Included parking in a secure garage.</Paragraph>
      <Paragraph>Intercom entry and secure access.</Paragraph>
      <Paragraph>
        Professional{' '}
        <Text mark strong>
          &nbsp;on-site manager&nbsp;
        </Text>
        .
      </Paragraph>
      <Paragraph>
        Fully equipped{' '}
        <Text mark strong>
          fitness center
        </Text>{' '}
        with quality equipment.
      </Paragraph>
      <Paragraph>Cat friendly, no dogs.</Paragraph>
    </div>
  ),
  images: [
    {
      src: 'assets/pics-min/Gym-Photo.jpg',
      caption: '',
      thumb: 'assets/pics-min/Gym-Photo.jpeg',
    },
    {
      src: 'assets/pics-min/4-Courtyard.jpg',
      caption: '',
      thumb: 'assets/pics-min/4-Courtyard.jpeg',
    },
    {
      src: 'assets/pics-min/lobby.jpg',
      caption: '',
      thumb: 'assets/pics-min/lobby.jpeg',
    },
  ],
};

const features = {
  sentence: (
    <div style={{ fontSize: '18px', lineHeight: '18px', textAlign: 'center' }}>
      <Paragraph>
        Bright, open floor plans built to{' '}
        <Text mark strong>
          condominium quality
        </Text>
        .
      </Paragraph>
      <Paragraph>Tall 9' to 12' ceilings.</Paragraph>
      <Paragraph>
        <Text mark strong>
          Full-size washer and dryer
        </Text>{' '}
        in every unit.
      </Paragraph>
      <Paragraph>
        <Text mark strong>
          Upgraded stainless steel appliances
        </Text>
        .
      </Paragraph>
      <Paragraph>New waterproof wood flooring, modern cabinets.</Paragraph>
      <Paragraph>
        <Text mark strong>
          Large balconies or patios
        </Text>
        .
      </Paragraph>
      <Paragraph>
        Spacious{' '}
        <Text mark strong>
          walk-in closets
        </Text>
      </Paragraph>
      <Paragraph>Granite kitchen counters.</Paragraph>
    </div>
  ),
  sentenceMore: (
    <div style={{ fontSize: '18px', lineHeight: '18px', textAlign: 'center' }}>
      <Row gutter={24}>
        <Col sm={24} md={12}>
          <Divider>Our Apartments</Divider>
          <Paragraph>All of the above and...</Paragraph>
          <Paragraph>New carpet in bedroom areas</Paragraph>
          <Paragraph>Ceramic tile in bathrooms</Paragraph>
          <Paragraph>Oversized bathrooms with stone counters</Paragraph>
          <Paragraph>
            <Text mark strong>
              Large oval tubs
            </Text>{' '}
            in all master bathrooms
          </Paragraph>
          <Paragraph>
            Two bedroom apartments have{' '}
            <Text mark strong>
              dual sinks
            </Text>{' '}
            with
          </Paragraph>
          <Paragraph>
            <Text mark strong>
              separate tub and shower
            </Text>{' '}
            in master bath.
          </Paragraph>
          <Paragraph>Recessed Lighting</Paragraph>
          <Paragraph>Central heat and air conditioning</Paragraph>
          <Paragraph>Glass shower enclosures</Paragraph>
        </Col>
        <Col sm={24} md={12}>
          <Divider>State-of-the-art Technology</Divider>
          <Paragraph>
            Advanced media box in each apartment for TV and Internet
          </Paragraph>
          <Paragraph>
            <Text mark strong>
              Dual pane windows
            </Text>{' '}
            with efficient low energy glazing
          </Paragraph>
          <Paragraph>
            Separate water system and{' '}
            <Text mark strong>
              individual water heaters
            </Text>{' '}
            in each unit
          </Paragraph>
          <Paragraph>
            <Text mark strong>
              Skylights
            </Text>{' '}
            in top floor units
          </Paragraph>
          <Paragraph>Controlled access entry with RFID and intercom</Paragraph>
          <Paragraph>
            Security cameras at entry and exit points of building
          </Paragraph>
          <Divider>Metro Art Quality</Divider>
          <Paragraph>Wide, well lit corridors decorated with artwork</Paragraph>
          <Paragraph>
            Bright and well ventilated above-ground parking located beneath the
            building
          </Paragraph>
          <Paragraph>
            Two oversized elevators with tall ceilings from parking to all
            levels
          </Paragraph>
          <Paragraph>Impressive two story lobby</Paragraph>
          <Paragraph>Landscaped courtyard with fountain</Paragraph>
        </Col>
      </Row>
    </div>
  ),
  images: [
    [
      {
        src: 'assets/pics-min/410entry.jpg',
        caption: '10901 Whipple St.',
        thumb: 'assets/pics-min/410entry.jpg',
      },
    ],
    {
      src: 'assets/pics-min/410washer.jpg',
      caption: '10901 Whipple St.',
      thumb: 'assets/pics-min/410washer.jpg',
    },
    {
      src: 'assets/pics-min/410-2nd-bath.jpg',
      caption: '10901 Whipple St.',
      thumb: 'assets/pics-min/410-2nd-bath.jpg',
    },
    {
      src: 'assets/pics-min/410-kitchen-large.jpg',
      caption: '10901 Whipple St.',
      thumb: 'assets/pics-min/410-kitchen-large.jpg',
    },
  ],
};

export const AppData: ApplicationData = {
  footer: (
    <div>
      <div>
        <Row
          align="middle"
          justify="center"
          style={{ margin: 12 }}
          gutter={[12, 12]}
        >
          <Col style={{ textAlign: 'center' }}>
            <img
              title={
                'Metro Art Toluca Lake Scored 98 out of 100 with Energy Star'
              }
              alt="Energy Star"
              src="./assets/ENE_crt_c.jpg"
            />
          </Col>
          <Col>
            <h2>98/100 with Energy Star</h2>
            Proud to be an Energy Star cerificated property.
          </Col>
        </Row>
        <Row>
          <Col md={24} xs={0}>
            Metro Art Toluca Lake Apartments — 10901 Whipple St, North
            Hollywood, CA 91602 — {PHONE_NUMBER}
          </Col>
          <Col md={0} xs={24}>
            Metro Art Toluca Lake Apartments
            <br />
            10901 Whipple St
            <br />
            North Hollywood, CA 91602
            <br />
            <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
          </Col>
        </Row>
      </div>
      <div style={{ fontSize: '80%', marginTop: '24px' }}>
        <div>
          The Metro Art Apartments is located in or around TOLUCA LAKE, STUDIO
          CITY, BURBANK, UNIVERSAL CITY, SHERMAN OAKS, SAN FERNANDO VALLEY,
          NORTH HOLLYWOOD
        </div>
        <div>Thank You For Looking, We Look Forward to Helping You!</div>
      </div>
    </div>
  ),
  sections: [
    {
      element: UI.Logo,
      props: {
        logo: (
          <img
            alt="logo"
            style={{ height: '50px' }}
            src="./assets/logoonly.png"
          />
        ),
        address: (
          <div style={{ lineHeight: 1 }}>
            <div style={{ fontSize: '110%' }}>Metro Art Apartments</div>
            <div style={{ fontSize: '80%' }}>10901 Whipple St.</div>
            <div style={{ fontSize: '80%' }}>North Hollywood, CA 91602</div>
            <div style={{ fontSize: '80%' }}>
              <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
            </div>
          </div>
        ),
        extra: (
          <img
            alt="Energy Star"
            style={{ height: '50px' }}
            title={
              'Metro Art Toluca Lake Scored 98 out of 100 with Energy Star'
            }
            src="./assets/ENE_crt_c.jpg"
          />
        ),
      },
    },
    {
      isNav: true,
      element: UI.Nav,
      props: {
        logo: {
          logo: (
            <img
              alt="logo"
              style={{ height: '50px' }}
              src="./assets/logoonly.png"
            />
          ),
          address: (
            <div style={{ lineHeight: 1 }}>
              <div style={{ fontSize: '110%' }}>Metro Art Apartments</div>
              <div style={{ fontSize: '80%' }}>10901 Whipple St.</div>
              <div style={{ fontSize: '80%' }}>North Hollywood, CA 91602</div>
              <div style={{ fontSize: '80%' }}>{PHONE_NUMBER}</div>
            </div>
          ),
        },
        rightSide: (
          <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
        ),
        rightSideModal: (
          <div>
            <Paragraph>
              <UI.VirtualToursButton tours={tours} />
            </Paragraph>
            <Paragraph>
              <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
            </Paragraph>
          </div>
        ),
      },
    },
    {
      element: UI.HeroImage,
      props: {
        image: './assets/hero.jpg',
      },
    },
    {
      element: UI.Tagline,
      props: {
        tagline:
          'Spacious, modern apartments in Toluca Lake. Central location, quiet neighborhood.',
        undertag: (
          <div>
            <UI.VirtualToursButton tours={tours} />
            <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
          </div>
        ),
      },
    },

    {
      element: UI.AboveFold,
      props: {
        images: [
          './assets/pics-min/fold-1.jpg',
          './assets/pics-min/fold-2.jpg',
          './assets/pics-min/fold-3.jpg',
          './assets/pics-min/fold-4.jpg',
          './assets/pics-min/fold-5.jpg',
        ],
      },
    },
    {
      element: UI.SectionTextCarousel,
      label: UI.NAV_ITEM.UNITS,
      nav: 'Units',
      props: {
        title: 'About Our Apartments',
        sections: [features],
      },
    },
    {
      element: UI.SectionTextCarousel,
      label: UI.NAV_ITEM.AMENITIES,
      nav: 'Amenities',
      props: {
        title: 'Community Amenities',
        sections: [amentities],
      },
    },
    {
      element: UI.SectionImages,
      label: UI.NAV_ITEM.FLOORPLANS,
      nav: 'Floorplans',
      props: {
        title: 'Floorplans',
        imageProps: { xs: 24, sm: 12, md: 12, lg: 8 },
        images: [
          './assets/floorplans/gable.jpg',
          './assets/floorplans/lombard.jpg',
          './assets/floorplans/hepburn.jpg',
          './assets/floorplans/bogart.jpg',
          './assets/floorplans/barrymore.jpg',
          './assets/floorplans/fairbanks.jpg',
        ],
      },
    },
    {
      element: UI.SectionTitle,
      label: UI.NAV_ITEM.CONTACT,
      nav: 'Tour',
      props: {
        title: 'Tour',
      },
    },
    {
      element: UI.SectionSideBySide,
      props: {
        leftSide: (
          <div>
            <h3>Schedule a tour with our on-site manager:</h3>
            <div style={{ marginTop: 24 }}>
              <h1 style={{}}>
                <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
              </h1>
              <div style={{ fontSize: '80%' }}>Call anytime!</div>
            </div>
            <div style={{ marginTop: 24 }}>
              <h1 style={{}}>
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
              </h1>
              <div style={{ fontSize: '80%' }}>
                Email us what you are looking for and some times that work for
                you.
              </div>
            </div>
            <div style={{ marginTop: 24 }}>
              <Paragraph>
                <UI.VirtualToursButton tours={tours} />
              </Paragraph>
              <Paragraph>
                <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
              </Paragraph>
            </div>
          </div>
        ),
        rightSide: (
          <iframe
            title="Property Location Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3301.9256391099116!2d-118.36959228451923!3d34.148244520111696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2be3d22cb2b71%3A0x33c837652dd9362!2s10901%20Whipple%20St%2C%20North%20Hollywood%2C%20CA%2091602!5e0!3m2!1sen!2sus!4v1596048550002!5m2!1sen!2sus"
            width="380"
            height="400"
            frameBorder={0}
            style={{ border: '1px solid #CCC' }}
          ></iframe>
        ),
      },
    },
  ],
};
